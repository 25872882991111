<template>

    <!-- ============================ Page Title Start================================== -->
    <div class="page-title search-form bl-dark">
        <div class="container">
            <div class="row mt-5 pt-3 justify-content-center">
                <div class="col-lg-10 col-md-10">
                    <form class="search-big-form shadows">
                        <div class="row m-0">
                            <div class="col-lg-12 col-md-12 col-sm-12 p-0">
                                <div class="form-group">
                                    <i class="bi bi-search"></i>
                                    <input type="text" class="form-control l-radius b-0 b-r" placeholder="Job Title or Keywords">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg">
        <div class="container">
            <div class="row">
                <!-- Item Wrap Start -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Filter Search -->
                            <div class="_filt_tag786">
                                <div class="form-group filter_group m-0">
                                    <div class="_tag780">32 Items found</div>
                                </div>
                                <div class="_tag785">
                                    <div class="_g78juy pr-3">
                                        <select class="form-control">
                                            <option>Sort by openings</option>
                                            <option>Show 30</option>
                                            <option>Show 40</option>
                                            <option>Show 50</option>
                                            <option>Show 100</option>
                                            <option>Show 200</option>
                                        </select>
                                    </div>
                                    <div class="_g78juy pr-3">
                                        <select class="form-control">
                                            <option>Sort by salary</option>
                                            <option>Show 30</option>
                                            <option>Show 40</option>
                                            <option>Show 50</option>
                                            <option>Show 100</option>
                                            <option>Show 200</option>
                                        </select>
                                    </div>
                                    <div class="_g78juy">
                                        <select class="form-control">
                                            <option>Sort by sector</option>
                                            <option>Show 30</option>
                                            <option>Show 40</option>
                                            <option>Show 50</option>
                                            <option>Show 100</option>
                                            <option>Show 200</option>
                                        </select>
                                    </div>
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-funnel"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <!-- Single Item -->
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="job_grid_02 shadow_0">
                                <div class="jb_types openings">03 Openings</div>
                                <div class="jb_grid_01_thumb">
                                    <a href="employer-detail.html"><img src="https://via.placeholder.com/100x100" class="img-fluid" alt=""></a>
                                </div>
                                <div class="jb_grid_01_caption">
                                    <h4 class="_jb_title"><a href="employer-detail.html">Google Infotech</a></h4>
                                    <div class="_emp_jb">Kanpur, India</div>
                                </div>
                                <div class="jb_grid_01_footer">
                                    <a href="job-detail-1.html" class="_jb_apply">View Job</a>
                                </div>
                            </div>
                        </div>

                        <!-- Single Item -->
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="job_grid_02 shadow_0">
                                <div class="jb_types openings">07 Openings</div>
                                <div class="jb_grid_01_thumb">
                                    <a href="employer-detail.html"><img src="https://via.placeholder.com/100x100" class="img-fluid" alt=""></a>
                                </div>
                                <div class="jb_grid_01_caption">
                                    <h4 class="_jb_title"><a href="employer-detail.html">Smart Technologies</a></h4>
                                    <div class="_emp_jb">Allahabad, UP</div>
                                </div>
                                <div class="jb_grid_01_footer">
                                    <a href="job-detail-1.html" class="_jb_apply">View Job</a>
                                </div>
                            </div>
                        </div>

                        <!-- Single Item -->
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="job_grid_02 shadow_0">
                                <div class="jb_types openings">10 Openings</div>
                                <div class="jb_grid_01_thumb">
                                    <a href="employer-detail.html"><img src="https://via.placeholder.com/100x100" class="img-fluid" alt=""></a>
                                </div>
                                <div class="jb_grid_01_caption">
                                    <h4 class="_jb_title"><a href="employer-detail.html">Shaurya Software</a></h4>
                                    <div class="_emp_jb">Canada, USA</div>
                                </div>
                                <div class="jb_grid_01_footer">
                                    <a href="job-detail-1.html" class="_jb_apply">View Job</a>
                                </div>
                            </div>
                        </div>

                        <!-- Single Item -->
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="job_grid_02 shadow_0">
                                <div class="jb_types openings">12 Openings</div>
                                <div class="jb_grid_01_thumb">
                                    <a href="employer-detail.html"><img src="https://via.placeholder.com/100x100" class="img-fluid" alt=""></a>
                                </div>
                                <div class="jb_grid_01_caption">
                                    <h4 class="_jb_title"><a href="employer-detail.html">Insta Technologies</a></h4>
                                    <div class="_emp_jb">Sahiwal, New York</div>
                                </div>
                                <div class="jb_grid_01_footer">
                                    <a href="job-detail-1.html" class="_jb_apply">View Job</a>
                                </div>
                            </div>
                        </div>

                        <!-- Single Item -->
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="job_grid_02 shadow_0">
                                <div class="jb_types openings">03 Openings</div>
                                <div class="jb_grid_01_thumb">
                                    <a href="employer-detail.html"><img src="https://via.placeholder.com/100x100" class="img-fluid" alt=""></a>
                                </div>
                                <div class="jb_grid_01_caption">
                                    <h4 class="_jb_title"><a href="employer-detail.html">Arpana Software</a></h4>
                                    <div class="_emp_jb">Lucknow, UP</div>
                                </div>
                                <div class="jb_grid_01_footer">
                                    <a href="job-detail-1.html" class="_jb_apply">View Job</a>
                                </div>
                            </div>
                        </div>

                        <!-- Single Item -->
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="job_grid_02 shadow_0">
                                <div class="jb_types openings">07 Openings</div>
                                <div class="jb_grid_01_thumb">
                                    <a href="employer-detail.html"><img src="https://via.placeholder.com/100x100" class="img-fluid" alt=""></a>
                                </div>
                                <div class="jb_grid_01_caption">
                                    <h4 class="_jb_title"><a href="employer-detail.html">Dribbble Duker</a></h4>
                                    <div class="_emp_jb">Liverpool, London</div>
                                </div>
                                <div class="jb_grid_01_footer">
                                    <a href="job-detail-1.html" class="_jb_apply">View Job</a>
                                </div>
                            </div>
                        </div>

                        <!-- Single Item -->
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="job_grid_02 shadow_0">
                                <div class="jb_types openings">04 Openings</div>
                                <div class="jb_grid_01_thumb">
                                    <a href="employer-detail.html"><img src="https://via.placeholder.com/100x100" class="img-fluid" alt=""></a>
                                </div>
                                <div class="jb_grid_01_caption">
                                    <h4 class="_jb_title"><a href="employer-detail.html">Sork Studio Inc.</a></h4>
                                    <div class="_emp_jb">Liverpool, London</div>
                                </div>
                                <div class="jb_grid_01_footer">
                                    <a href="job-detail-1.html" class="_jb_apply">View Job</a>
                                </div>
                            </div>
                        </div>

                        <!-- Single Item -->
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="job_grid_02 shadow_0">
                                <div class="jb_types openings">11 Openings</div>
                                <div class="jb_grid_01_thumb">
                                    <a href="employer-detail.html"><img src="https://via.placeholder.com/100x100" class="img-fluid" alt=""></a>
                                </div>
                                <div class="jb_grid_01_caption">
                                    <h4 class="_jb_title"><a href="employer-detail.html">Blue Bird Soft</a></h4>
                                    <div class="_emp_jb">Montreal, Canada</div>
                                </div>
                                <div class="jb_grid_01_footer">
                                    <a href="job-detail-1.html" class="_jb_apply">View Job</a>
                                </div>
                            </div>
                        </div>

                        <!-- Single Item -->
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="job_grid_02 shadow_0">
                                <div class="jb_types openings">06 Openings</div>
                                <div class="jb_grid_01_thumb">
                                    <a href="employer-detail.html"><img src="https://via.placeholder.com/100x100" class="img-fluid" alt=""></a>
                                </div>
                                <div class="jb_grid_01_caption">
                                    <h4 class="_jb_title"><a href="employer-detail.html">Waft Technologies</a></h4>
                                    <div class="_emp_jb">Denever, United States</div>
                                </div>
                                <div class="jb_grid_01_footer">
                                    <a href="job-detail-1.html" class="_jb_apply">View Job</a>
                                </div>
                            </div>
                        </div>


                    </div>

                    <base-pagination>
                    </base-pagination>

                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->
</template>

<script>
// @ is an alias to /src

export default {
  name: "BrowseJobs",
};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

</style>
